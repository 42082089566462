.container {
    max-width: 1200px;
  }
  
  .text-primary {
    color: #ffe600;
  }
  
  .rounded-lg {
    border-radius: 8px;
  }
  
  .shadow-lg {
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
  }
  
  button {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }
  
  button:hover {
    opacity: 0.9;
  }