.banner {  
    flex-direction: row;
    text-align: left;
    gap: 30px;
    background: url("../images/dofon.jpg");
    background-size: cover;
    background-position: center;
    color: #f7f7f7;
  }

  .banner-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }