.product-gallery {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    /*grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));*/
    grid-gap: 1rem;
    margin-top: 1rem;    
  }
  
  .product {
    border: 1px solid #ccc;
    padding: 1rem;
  }
  
  .product img {
    width: 100%;
    height: auto;
  }