@keyframes animate {
    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }
    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }
}

.background {
    /*position: fixed;*/
    position: relative;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    background: #5c5c5c;
    overflow: hidden;
}
.background li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 16s linear infinite;
}




.background li:nth-child(0) {
    left: 20%;
    width: 183px;
    height: 183px;
    bottom: -183px;
    animation-delay: 1s;
}
.background li:nth-child(1) {
    left: 62%;
    width: 174px;
    height: 174px;
    bottom: -174px;
    animation-delay: 5s;
}
.background li:nth-child(2) {
    left: 65%;
    width: 122px;
    height: 122px;
    bottom: -122px;
    animation-delay: 4s;
}
.background li:nth-child(3) {
    left: 42%;
    width: 118px;
    height: 118px;
    bottom: -118px;
    animation-delay: 9s;
}
.background li:nth-child(4) {
    left: 36%;
    width: 104px;
    height: 104px;
    bottom: -104px;
    animation-delay: 5s;
}
.background li:nth-child(5) {
    left: 32%;
    width: 110px;
    height: 110px;
    bottom: -110px;
    animation-delay: 11s;
}
.background li:nth-child(6) {
    left: 33%;
    width: 165px;
    height: 165px;
    bottom: -165px;
    animation-delay: 19s;
}
.background li:nth-child(7) {
    left: 84%;
    width: 112px;
    height: 112px;
    bottom: -112px;
    animation-delay: 1s;
}
.background li:nth-child(8) {
    left: 41%;
    width: 186px;
    height: 186px;
    bottom: -186px;
    animation-delay: 33s;
}
.background li:nth-child(9) {
    left: 66%;
    width: 181px;
    height: 181px;
    bottom: -181px;
    animation-delay: 21s;
}
.background li:nth-child(10) {
    left: 5%;
    width: 127px;
    height: 127px;
    bottom: -127px;
    animation-delay: 49s;
}
.background li:nth-child(11) {
    left: 35%;
    width: 110px;
    height: 110px;
    bottom: -110px;
    animation-delay: 41s;
}
.background li:nth-child(12) {
    left: 30%;
    width: 144px;
    height: 144px;
    bottom: -144px;
    animation-delay: 4s;
}
.background li:nth-child(13) {
    left: 43%;
    width: 127px;
    height: 127px;
    bottom: -127px;
    animation-delay: 34s;
}
.background li:nth-child(14) {
    left: 53%;
    width: 101px;
    height: 101px;
    bottom: -101px;
    animation-delay: 41s;
}
.background li:nth-child(15) {
    left: 66%;
    width: 198px;
    height: 198px;
    bottom: -198px;
    animation-delay: 62s;
}
.background li:nth-child(16) {
    left: 36%;
    width: 162px;
    height: 162px;
    bottom: -162px;
    animation-delay: 72s;
}
.background li:nth-child(17) {
    left: 56%;
    width: 125px;
    height: 125px;
    bottom: -125px;
    animation-delay: 83s;
}
.background li:nth-child(18) {
    left: 64%;
    width: 178px;
    height: 178px;
    bottom: -178px;
    animation-delay: 55s;
}
.background li:nth-child(19) {
    left: 46%;
    width: 194px;
    height: 194px;
    bottom: -194px;
    animation-delay: 32s;
}
.background li:nth-child(20) {
    left: 23%;
    width: 158px;
    height: 158px;
    bottom: -158px;
    animation-delay: 18s;
}
.background li:nth-child(21) {
    left: 76%;
    width: 108px;
    height: 108px;
    bottom: -108px;
    animation-delay: 80s;
}
.background li:nth-child(22) {
    left: 55%;
    width: 124px;
    height: 124px;
    bottom: -124px;
    animation-delay: 26s;
}
.background li:nth-child(23) {
    left: 15%;
    width: 136px;
    height: 136px;
    bottom: -136px;
    animation-delay: 21s;
}
.background li:nth-child(24) {
    left: 24%;
    width: 130px;
    height: 130px;
    bottom: -130px;
    animation-delay: 25s;
}
.background li:nth-child(25) {
    left: 48%;
    width: 119px;
    height: 119px;
    bottom: -119px;
    animation-delay: 35s;
}
.background li:nth-child(26) {
    left: 75%;
    width: 194px;
    height: 194px;
    bottom: -194px;
    animation-delay: 76s;
}
.background li:nth-child(27) {
    left: 12%;
    width: 169px;
    height: 169px;
    bottom: -169px;
    animation-delay: 85s;
}
.background li:nth-child(28) {
    left: 73%;
    width: 101px;
    height: 101px;
    bottom: -101px;
    animation-delay: 106s;
}
.background li:nth-child(29) {
    left: 79%;
    width: 181px;
    height: 181px;
    bottom: -181px;
    animation-delay: 68s;
}
.background li:nth-child(30) {
    left: 66%;
    width: 131px;
    height: 131px;
    bottom: -131px;
    animation-delay: 59s;
}
.background li:nth-child(31) {
    left: 9%;
    width: 149px;
    height: 149px;
    bottom: -149px;
    animation-delay: 138s;
}
.background li:nth-child(32) {
    left: 86%;
    width: 167px;
    height: 167px;
    bottom: -167px;
    animation-delay: 117s;
}
.background li:nth-child(33) {
    left: 83%;
    width: 164px;
    height: 164px;
    bottom: -164px;
    animation-delay: 96s;
}
.background li:nth-child(34) {
    left: 65%;
    width: 191px;
    height: 191px;
    bottom: -191px;
    animation-delay: 154s;
}
.background li:nth-child(35) {
    left: 19%;
    width: 152px;
    height: 152px;
    bottom: -152px;
    animation-delay: 26s;
}
.background li:nth-child(36) {
    left: 21%;
    width: 191px;
    height: 191px;
    bottom: -191px;
    animation-delay: 92s;
}
.background li:nth-child(37) {
    left: 67%;
    width: 159px;
    height: 159px;
    bottom: -159px;
    animation-delay: 176s;
}
.background li:nth-child(38) {
    left: 3%;
    width: 173px;
    height: 173px;
    bottom: -173px;
    animation-delay: 68s;
}
.background li:nth-child(39) {
    left: 9%;
    width: 185px;
    height: 185px;
    bottom: -185px;
    animation-delay: 13s;
}
.background li:nth-child(40) {
    left: 60%;
    width: 162px;
    height: 162px;
    bottom: -162px;
    animation-delay: 85s;
}
.background li:nth-child(41) {
    left: 77%;
    width: 156px;
    height: 156px;
    bottom: -156px;
    animation-delay: 156s;
}
.background li:nth-child(42) {
    left: 57%;
    width: 159px;
    height: 159px;
    bottom: -159px;
    animation-delay: 79s;
}
.background li:nth-child(43) {
    left: 41%;
    width: 176px;
    height: 176px;
    bottom: -176px;
    animation-delay: 199s;
}